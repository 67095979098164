import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: '登录',
        component: () => import('@/views/Login.vue')
    }, {
        path: '/Index',
        name: '首页框架',
        component: () => import('@/views/Index.vue'),
        children: [{
            path: '/OverviewManagment',
            name: '统计概况',
            component: () => import('@/views/Pages/OverviewManagment.vue'),
            meta: {
                icon: 'tongjigaikuang',
                isShow: true
            }
        },
        {
            path: '/VideoIndex',
            name: '视频管理',
            component: () => import('@/views/Pages/VideoManagment/VideoIndex.vue'),
            meta: {
                icon: 'shipinguanli',
                isShow: true
            },
            children: [{
                path: '/VideoSubject',
                name: '学科视频',
                component: () => import('@/views/Pages/VideoManagment/VideoSubject.vue'),
                meta: {
                    // icon: 'xueke',
                    isShow: true
                }
            }, {
                path: '/VideoSelectSubject',
                name: '选科视频',
                component: () => import('@/views/Pages/VideoManagment/VideoSelectSubject.vue'),
                meta: {
                    // icon: 'xueke',
                    isShow: true
                }
            }, {
                path: '/VideoVoluntary',
                name: '志愿填报',
                component: () => import('@/views/Pages/VideoManagment/VideoVoluntary.vue'),
                meta: {
                    // icon: 'xueke',
                    isShow: true
                }
            }, {
                path: '/VideoEducation',
                name: '家庭教育',
                component: () => import('@/views/Pages/VideoManagment/VideoEducation.vue'),
                meta: {
                    // icon: 'xueke',
                    isShow: true
                }
            }]
        },
        {
            path: '/ArticleManagment',
            name: '文章管理',
            component: () => import('@/views/Pages/ArticleManagment.vue'),
            meta: {
                icon: 'wenzhangguanli',
                isShow: true
            }
        },
        {
            path: '/ArticleManagmentEdit',
            name: '文章管理编辑',
            component: () => import('@/views/Pages/ArticleManagmentEdit.vue'),
            meta: {
                icon: 'wenzhangguanli',
            }
        },
        {
            path: '/ConfigEducation',
            name: '教育分类',
            component: () => import('@/views/Pages/ConfigManagment/ConfigEducation.vue'),
            meta: {
                icon: 'jiaoyu',
                isShow: true
            }
        }, 
        {
            path: '/ConfigIndex',
            name: '配置管理',
            component: () => import('@/views/Pages/ConfigManagment/ConfigIndex.vue'),
            meta: {
                icon: 'peizhiguanli',
                isShow: true
            },
            children: [
            //     {
            //     path: '/ConfigType',
            //     name: '学科类别',
            //     component: () => import('@/views/Pages/ConfigManagment/ConfigType.vue'),
            //     meta: {
            //         // icon: 'xueke',
            //         isShow: true
            //     }
            // },
             
            // {
            //     path: '/CongifGrade',
            //     name: '年级管理',
            //     component: () => import('@/views/Pages/ConfigManagment/CongifGrade.vue'),
            //     meta: {
            //         // icon: 'nianji',
            //         isShow: true
            //     }
            // }, {
            //     path: '/ConfigSemester',
            //     name: '学期管理',
            //     component: () => import('@/views/Pages/ConfigManagment/ConfigSemester.vue'),
            //     meta: {
            //         // icon: 'xueqi',
            //         isShow: true
            //     }
            // }, {
            //     path: '/ConfigSubject',
            //     name: '科目管理',
            //     component: () => import('@/views/Pages/ConfigManagment/ConfigSubject.vue'),
            //     meta: {
            //         // icon: 'kemu',
            //         isShow: true
            //     }
            // }, 
            {
                path: '/ConfigBanner',
                name: '轮播管理',
                component: () => import('@/views/Pages/ConfigManagment/ConfigBanner.vue'),
                meta: {
                    // icon: 'lunbo',
                    isShow: true
                }
            }, {
                path: '/ConfigNotice',
                name: '公告管理',
                component: () => import('@/views/Pages/ConfigManagment/ConfigNotice.vue'),
                meta: {
                    // icon: 'gonggao',
                    isShow: true
                }
            }, {
                path: '/ConfigAboutUs',
                name: '关于我们',
                component: () => import('@/views/Pages/ConfigManagment/ConfigAboutUs.vue'),
                meta: {
                    // icon: 'guanyu',
                    isShow: true
                }
            }]
        },

        {
            path: '/ConfigChapter',
            name: '章节管理',
            component: () => import('@/views/Pages/ConfigManagment/ConfigChapter.vue'),
            meta: {
                icon: 'zhangjie',
                isShow: true
            }
        }, {
            path: '/ConfigKnowledge',
            name: '知识点管理',
            component: () => import('@/views/Pages/ConfigManagment/ConfigKnowledge.vue'),
            meta: {
                icon: 'zhishidian',
                isShow: true
            }
        }, {
            path: '/StudentManagment',
            name: '学生管理',
            component: () => import('@/views/Pages/StudentManagment.vue'),
            meta: {
                icon: 'xuesheng',
                isShow: true
            }
        }, {
            path: '/StudentBrowseVideo',
            name: '学生管理视频浏览',
            component: () => import('@/views/Pages/StudentBrowseVideo.vue'),
            meta: {
                icon: 'xuesheng',
            }
        }, {
            path: '/TeacherManagment',
            name: '教师管理',
            component: () => import('@/views/Pages/TeacherManagment.vue'),
            meta: {
                icon: 'jiaoshi',
                isShow: true
            }
        }, {
            path: '/AccountManagement',
            name: '账号管理',
            component: () => import('@/views/Pages/AccountManagement.vue'),
            meta: {
                icon: 'zhanghaoguanli',
                isShow: true
            }
        }]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
