import { createStore } from 'vuex'
import persistedState from 'vuex-persistedstate'
export default createStore({
    plugins: [
        persistedState({
            storage: window.sessionStorage
        })
    ],
    
    state: sessionStorage.getItem('vuex') ? JSON.parse(sessionStorage.getItem('vuex')) : {
        _token: '',
        loginInfo: {},
        loading: false,
    },
    mutations: {
        setUpdateToken(state, payload) {
            state._token = payload
        },
        setUpdateLoginInfo(state, payload) {
            state.loginInfo = payload
        },
        setUpdateLoading(state, payload) {
            state.loading = payload
        }
    },
    actions: {
        setUpdateToken(context, _token) {
            context.commit('setUpdateToken', _token)
        },
        setUpdateLoginInfo(context, info) {
            context.commit('setUpdateLoginInfo', info)
        },
        setUpdateLoading(context, load) {
            context.commit('setUpdateLoading', load)
        }
    },
    getters: {
        isLoading: (state) => {
            return state.loading
        }
    },
    modules: {}
})
