import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';

import Antd from 'ant-design-vue';
import animated from 'animate.css'
import './style/theme.less';
import http from './plugins/http.js';


const app = createApp(App)
app.config.globalProperties.http = http
app.config.globalProperties.domain = http.domain
app.config.globalProperties.projectName = process.env.VUE_APP_TITLE
app.config.productionTip = false;

app.use(store).use(router).use(Antd).use(animated).mount('#app')

// 全局引入图标------------------------------start---------------------------------------------
import { createFromIconfontCN } from '@ant-design/icons-vue';
const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_3399357_j9lwnl7m8en.js',
});
app.component('IconFont', IconFont);
// 全局引入图标------------------------------end-----------------------------------------------

// 全局引入 message------------------------------start---------------------------------------------
import { message } from 'ant-design-vue';
app.config.globalProperties.message = message
// 全局引入 message------------------------------end---------------------------------------------
