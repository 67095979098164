import axios from 'axios'
import { message } from 'ant-design-vue';
import router from '../router'
import store from '../store'
axios.defaults.withCredentials = true; //设置cross跨域 并设置访问权限 允许跨域携带cookie信息
var domain = process.env.VUE_APP_BASE_API;
console.log(domain);
export default {
    domain: domain,
    // load是否显示加载中，默认是
    post(url, param, load = true) {
        return new Promise((cback, reject) => {
            if (load) store.dispatch('setUpdateLoading', true);
            axios.post(domain + url, param, {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-TOKEN': store.state._token
                }
            }).then(re => {
                if (re.data._token && (store.state._token != re.data._token)) store.dispatch('setUpdateToken', re.data._token);
                var code = re.data.code.toString();
                if (code == '200') {
                    cback(re.data.data);
                } else if (code == '201') { //登录超时
                    store.dispatch('setUpdateToken', '');
                    store.dispatch('setUpdateLoginInfo', '');
                    router.push('/')
                    message.error(re.data.message);
                }else {
                    message.error(re.data.message);
                    reject()
                }
                if (load) {
                    store.dispatch('setUpdateLoading', false);
                }
            }).catch(err => {
                if (load) store.dispatch('setUpdateLoading', false);
                message.error('请求错误');
            })
        })
    },

    go(url, param) {
        router.push({
            path: url,
            query: param
        })
    },
    goBack() {
        router.back()
    },
}
